// Override default variables before the import
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

$lightblue: #98c0e0;
$lightestblue: #f2f6fb;
$darkblue: rgb(0, 31, 59);
$darkestblue: #010c27;
$white: #ffffff;

@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@200;300;400;500;600;700&display=swap");

body {
  font-family: "Bai Jamjuree", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 137%;
  color: $darkblue;
  overflow: hidden;
  overflow-y: scroll;
}

h1 {
  font-weight: 300;
  font-size: 60px;
  line-height: 136%;
  color: $white;
  @include media-breakpoint-down(md) {
    font-size: 46px;
  }
}

h2 {
  font-size: 52px;
  line-height: 110%;
  font-weight: 700;
  @include media-breakpoint-down(md) {
    font-size: 46px;
  }
}

h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 32px;
  line-height: 124%;
  font-weight: 700;
  color: $white;
}

h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 135%;
}

h5 {
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
}

img {
  width: 100%;
  height: auto;
}

ul {
  border-top: 1px solid $darkblue;
  li {
    border-bottom: 1px solid $darkblue;
    padding: 10px 0;
  }
}

.mobile {
  @include media-breakpoint-down(xl) {
    display: none;
  }

  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.desktop {
  @include media-breakpoint-down(xl) {
    display: block;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

/******************************
/
/     Header Module
/
******************************/

.header {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background: transparent;
  .wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 28px 65px 20px 65px;
    ul {
      margin-left: 20px;
      border: 0 none;
      li {
        display: inline;
        color: $white;
        font-size: 16px;
        margin-left: 36px;
        border: 0 none;
        cursor: pointer;
      }
    }
  }
  transition: all 0.5s;
  &.sticky {
    background-color: #001f3b;
    box-shadow: 1px 1px 14px 10px rgba(0, 31, 59, 0.75);
    transition: all 0.5s;
  }

  @include media-breakpoint-down(lg) {
    .wrapper {
      padding: 20px 32px 18px 32px;
      .logo {
        svg {
          height: 30px;
          width: auto;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .product-menu {
      opacity: 0;
      height: 0vh;
      overflow: hidden;
      background: $darkblue;
      ul {
        padding: 100px 20px;

        li {
          color: $white;
          border-color: $white;
          font-size: 30px;
          line-height: 80px;
        }
      }
      &.open {
        opacity: 1;
        height: 100vh;
        overflow: visible;
      }
    }
  }

  // @include media-breakpoint-down(xl) {
  //   border-top: 10px solid red;
  // }
  // @include media-breakpoint-down(lg) {
  //   border-top: 10px solid orange;
  // }
  // @include media-breakpoint-down(md) {
  //   border-top: 10px solid yellow;
  // }
  // @include media-breakpoint-down(sm) {
  //   border-top: 10px solid green;
  // }
  // @include media-breakpoint-down(xs) {
  //   border-top: 10px solid blue;
  // }
}

/******************************
/
/     Banner Module
/
******************************/

.banner {
  scroll-margin: 60px;
  scroll-snap-type: y mandatory;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  .banner-color {
    position: absolute;
    height: 100vh;
    width: 100%;
    background: rgb(0, 31, 59);
    opacity: 0.7;
  }
  .wrapper {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 65px;
    .align-bottom {
      position: absolute;
      top: 42vh;
      h2,
      h1 {
        color: $lightblue;
        margin-top: 0px;
        margin-bottom: 0px;
        line-height: 110%;
        font-weight: 700;
        max-width: 600px;
      }
      h2 {
        color: $white;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .wrapper {
      padding: 20px 32px 18px 32px;
    }
  }
  @include media-breakpoint-down(sm) {
    .wrapper {
      h2,
      h1 {
        font-size: 40px;
        line-height: 110%;
        padding-right: 70px;
      }
      padding: 20px 60px 18px 20px;
    }
  }
}

/******************************
/
/     Two Column Text Module
/
******************************/

.two-column-text {
  scroll-margin: 60px;
  scroll-snap-type: y mandatory;
  width: 100%;
  margin: 128px 0;
  .wrapper {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 65px;
    @include media-breakpoint-down(lg) {
      padding: 20px 32px 18px 32px;
    }
  }
}

/******************************
/
/     Title Image Module
/
******************************/

.title-image {
  scroll-margin: 60px;

  scroll-snap-type: y mandatory;
  width: 100%;
  background: rgb(0, 31, 59);
  padding: 120px 0 65px 0;
  .wrapper {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    padding: 60px 60px;
    h3 {
      position: absolute;
      left: 65px;
      top: -60px;
    }
    @include media-breakpoint-down(lg) {
      padding: 20px 32px 18px 32px;
    }
    @include media-breakpoint-down(md) {
      padding: 20px 20px 18px 20px;
      h3 {
        position: static;
        margin-left: 65px;
      }
    }
    @include media-breakpoint-down(sm) {
      padding: 20px 20px 18px 20px;
      h3 {
        position: static;
        margin-left: 0px;
      }
    }
  }
}

/******************************
/
/     Feature Panel Module
/
******************************/

.feature-panel {
  scroll-margin: 60px;

  scroll-snap-type: y mandatory;
  width: 100%;
  background: $lightblue;
  .wrapper {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    padding: 120px 60px 100px 60px;
    h2 {
      margin-bottom: 60px;
    }
    .box {
      background: $white;
      height: 370px;
      margin-bottom: 30px;
      padding: 50px;
      h1 {
        font-size: 88px;
        line-height: 110%;
        font-weight: 700;
        color: $darkblue;
      }
      p {
        font-size: 28px;
        line-height: 134%;
        font-weight: 500;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .wrapper {
      padding: 60px 20px;
      .box {
        h1 {
          font-size: 60px;
        }
        padding: 20px;
        height: 280px;
        p {
          font-size: 24px;
          line-height: 120%;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .wrapper {
      .box {
        height: 250px;
        p {
          font-size: 22px;
        }
      }
    }
  }
}

/******************************
/
/     Text Image Module
/
******************************/

.text-image {
  scroll-margin: 60px;
  scroll-snap-type: y mandatory;
  width: 100%;
  .wrapper {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 65px;
    .row {
      height: 970px;
      .col-md-6 {
        display: flex;
        align-items: center;
        justify-content: center;
        .content {
          padding: 0 32% 0 0;
          p {
            padding: 5px 10% 7px 0;
          }
        }
        img {
          max-width: 650px;
          max-height: 650px;
          margin: 40px;
          width: auto;
        }
      }
    }
  }
  &.blue {
    background: linear-gradient(
      90deg,
      $white 0%,
      $white 50%,
      $lightestblue 50%,
      $lightestblue 100%
    );
    .row {
      .col-md-6 {
        &:last-child {
          background: $lightestblue;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      background: none;
      .row {
        .col-md-6 {
          img {
            max-width: 300px;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    background: none;
    .wrapper {
      padding: 0px 20px;
      h2 {
        font-size: 40px;
      }
      .row {
        .col-md-6 {
          display: flex;
          align-items: center;
          justify-content: center;
          .content {
            padding: 0 20% 0 0;
            p {
              padding: 5px 0 7px 0;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .wrapper {
      padding: 60px 20px 0px 20px;
      h2 {
        font-size: 35px;
      }
      .row {
        height: auto;
        padding-top: 40px;
        border-top: 1px solid $lightblue;
        .col-md-6 {
          img {
            margin: 40px -20px;
            padding: 0 20px;
            max-width: 300px;
            height: auto;
          }
        }
      }
    }
  }
}

/******************************
/
/     Text Over Image Module
/
******************************/

.text-over-image {
  scroll-margin: 60px;
  scroll-snap-type: y mandatory;
  height: 970px;
  background-color: $darkestblue;
  background-repeat: no-repeat;
  background-position: center center;
  .wrapper {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 65px;
    color: $white;
    border-color: $white;
    padding-top: 140px;
    ul {
      width: 100%;
      margin-top: 60px;
      font-size: 20px;
      border-top: 1px solid $white;
      li {
        border-bottom: 1px solid $white;
        padding: 9px 30px 9px 0;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    background-size: 400px auto;
    height: 700px;
    .wrapper {
      padding: 60px 20px 0px 20px;
      ul {
        max-width: 75%;
        li {
          border-bottom: 1px solid $white;
          padding: 9px 0px 9px 0;
          font-size: 18px;
        }
      }
    }
  }
}

/******************************
/
/     Text Image Module
/
******************************/

.image-text {
  scroll-margin: 60px;

  scroll-snap-type: y mandatory;
  width: 100%;
  .wrapper {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 65px;
    .row {
      height: 970px;
      .col-md-6 {
        display: flex;
        align-items: center;
        justify-content: center;
        .content {
          padding: 0 32% 0 0;
          p {
            padding: 5px 10% 7px 0;
          }
        }
        img {
          max-width: 750px;
          max-height: 750px;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    background: none;
    .wrapper {
      padding: 0px 20px;
      h2 {
        font-size: 40px;
      }
      .row {
        .col-md-6 {
          display: flex;
          align-items: center;
          justify-content: center;
          .content {
            padding: 0 20% 0 0;
            p {
              padding: 5px 0 7px 0;
            }
          }
          img {
            width: 80%;
            height: auto;
            margin: 40px;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .wrapper {
      h2 {
        font-size: 35px;
      }
      .row {
        height: auto;
        border-top: 1px solid $lightblue;
        padding-top: 40px;
        .col-md-6 {
          img {
            max-height: 400px;
          }
        }
      }
    }
  }
}

/******************************
/
/     Statistics Module
/
******************************/

.statistics-panel {
  scroll-margin: 60px;

  scroll-snap-type: y mandatory;
  background: $darkblue;
  width: 100%;
  .wrapper {
    color: $white;
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    padding: 120px 65px;
    h2 {
      margin-bottom: 90px;
    }
    .number-panel {
      background: url("../images/corner.svg") -6px -6px no-repeat;
      overflow: hidden;
      border: 1px solid $white;
      overflow: hidden;
      padding: 20px 30px 10px 30px;
      margin-bottom: 32px;
      h1 {
        color: $lightblue;
        font-size: 140px;
        line-height: 110%;
        font-weight: 700;
        display: inline-block;
        position: relative;
        top: -5px;
      }
      p {
        color: #fff;
        font-size: 52px;
        line-height: 110%;
        font-weight: 700;
        text-transform: uppercase;
        display: inline-block;
        width: 100%;
        margin-left: 40px;
      }
      &.number-text {
        display: flex;
        align-items: center;
        padding-right: 100px;
        &.padding {
          padding-right: 300px;
          height: 250px;
          @include media-breakpoint-down(md) {
            height: auto;
          }
        }
      }

      &.text-number {
        height: 446px;
        text-align: center;
        padding: 20px 40px 10px 40px;
        p {
          margin: 0;
        }
        .number {
          p {
            color: $lightblue;
          }
        }
        @include media-breakpoint-down(lg) {
          height: 370px;
        }
        @include media-breakpoint-down(md) {
          height: auto;
          padding-bottom: 10px;
        }
      }
      &.no-number-text {
        height: 275px;
        p {
          margin: 0;
        }
        img {
          margin: 22px 0;
        }
      }
      &.no-number-text-image-right {
        display: flex;
        align-items: center;
        position: relative;
        height: 275px;
        p {
          margin: 0;
          max-width: 70%;
        }
        img {
          position: absolute;
          width: 200px;
          height: auto;
          bottom: 10px;
          right: 20px;
        }
        @include media-breakpoint-down(md) {
          height: auto;
          img {
            width: 140px;
          }
        }
      }
      &.no-number-text-image-left {
        display: flex;
        align-items: center;
        position: relative;
        height: 250px;
        p {
          margin: 0 0 0 40px;
          max-width: 70%;
        }
        img {
          width: 200px;
          height: auto;
        }
        @include media-breakpoint-down(sm) {
          img {
            width: 80px;
          }
          height: auto;
        }
      }
      &.no-number-text-no-image {
        display: flex;
        align-items: center;
        padding-right: 70px;
        height: 250px;
        p {
          margin: 0;
          position: relative;
          top: -8px;
        }
        @include media-breakpoint-down(md) {
          height: auto;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .wrapper {
      .number-panel {
        h1 {
          font-size: 90px;
        }
        p {
          font-size: 44px;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .wrapper {
      padding: 60px 20px;
      .number-panel {
        h1 {
          font-size: 70px;
        }
        p {
          font-size: 35px;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .wrapper {
      padding: 60px 20px;
      .number-panel {
        h1 {
          font-size: 40px;
        }
        p {
          font-size: 24px;
        }
      }
    }
  }
}

/******************************
/
/     Testimonials Module
/
******************************/

.testimonials {
  scroll-margin: 60px;

  scroll-snap-type: y mandatory;
  background: $darkblue;
  width: 100%;
  .wrapper {
    color: $white;
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    padding: 120px 65px;
    h2 {
      margin-bottom: 90px;
    }
    .box {
      color: $darkblue;
      min-height: 330px;
      margin-bottom: 32px;
      padding: 38px;
      background-color: #fff;
      font-size: 38px;
      line-height: 135%;
    }
    .even-0,
    .even-3 {
      background: $lightblue;
    }
  }
  @include media-breakpoint-down(md) {
    .wrapper {
      padding: 60px 20px;
      .box {
        min-height: 250px;
        p {
          font-size: 24px;
          line-height: 35px;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .wrapper {
      padding: 60px 20px;
      h2 {
        font-size: 40px;
      }
      .box {
        min-height: 100px;
        p {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
}

/******************************
/
/     Contact Module
/
******************************/

.contact-panel {
  scroll-margin: 60px;
  scroll-snap-type: y mandatory;
  background: $darkblue;
  width: 100%;
  .wrapper {
    color: $white;
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    padding: 120px 65px;
    h2 {
      margin-bottom: 90px;
    }
    .large {
      p,
      a {
        color: $white;
        font-size: 24px;
        line-height: 134%;
        font-weight: 500;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .wrapper {
      padding: 60px 20px;
      .box {
        min-height: 100px;
        p {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
}
